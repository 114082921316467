import {post} from "./request";

export let getNewAccessToken = async (accessToken, refreshToken) => {
    const data = await post('/v1/generateToken', {
        headers: {
            "Content-Type": "application/json",
            language: "en",
            lan: "en",
            platform: 3,
            currencySymbol: "$",
            // currencyCode: "USD"
            currencyCode: window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "AUD"
        },
        body: {
            refToken: accessToken,
            accessToken: refreshToken
        }
    })
    return data;
}