// import { get, getForReport } from "../lib/request";
import { getForReport } from "../lib/request";

// Ride Count Analytics
export const fetchRideStatus = (
  start_time,
  end_time,
  group_by,
  status,
  device,
  country_id,
  city_id,
  zone_id
) => {
  let URL = `/tow/status?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&vehicle_type_id=0&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};
