import React from "react";
import { Map, TileLayer } from "react-leaflet";
import HeatmapLayer from "./src/HeatmapLayer";
import { addressPoints } from "./realworld";
//
import ReactSelect from "../../../components/reactSelect/reactSelect";
import Filters from "../../../components/filter/location/filter";
import moment from "moment"; // change the date format
import Snackbar from "../../../components/snackbar/snackbar"; // danger popup for error message (Data Not Found) etc
import _ from "lodash";
import { dateTimeFormatForGraph } from "../../../lib/helper";
// import Headers from "./header/index.js"; // drop down icons for daterange and location filters
import {
  getCities,
  getCountries,
  getZones,
} from "../../../services/rideLocationFilter";
import { HeatMapAPI } from "../../../services/heatMap";
import { CircularProgress } from "@material-ui/core";

/**
 * @Author Jai
 * @Date 27 April, 2021
 * @Description Created a Heatmap along with filters such as location [country city zone], date range, device type, and location filter
 */

class RideHeatMap extends React.Component {
  state = {
    mapHidden: false,
    layerHidden: false,
    addressPoints,
    radius: 25,
    blur: 15,
    max: 1.0,
    limitAddressPoints: true,

    startDate:
      localStorage.getItem("startDate") != null
        ? localStorage.getItem("startDate")
        : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate:
      localStorage.getItem("endDate") != null
        ? localStorage.getItem("endDate")
        : moment().format("YYYY-MM-DD HH:mm:ss"),

    dataLoaded: false,
    loadingScreen: true,
    //snackbar error msg
    snackBarMessage: "",
    showSnackbar: false,

    //country filter
    country: "",
    city: "",
    countryId: "",
    cityId: "",
    countries: [],
    cities: [],

    locationStatus: [
      { label: "Pickup", value: 1 },
      { label: "Drop", value: 2 },
    ],
    selectedLocationStatus: { label: "Pickup", value: 1 },

    sortByDevice: [
      { label: "All", value: 0 },
      { label: "Android", value: 1 },
      { label: "iOS", value: 2 },
    ],
    selectedSortByDevice: { label: "All", value: 0 },
  };

  componentDidMount = () => {
    this.handleFilterData("", "initialCall");
    this.getData(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedLocationStatus.value,
      this.state.selectedSortByDevice.value,
      this.state.countryId,
      this.state.cityId,
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.countryId !== this.state.countryId ||
      prevState.cityId !== this.state.cityId ||
      this.state.selectedLocationStatus.value !==
        prevState.selectedLocationStatus.value ||
      this.state.selectedSortByDevice.value !==
        prevState.selectedSortByDevice.value
    ) {
      this.setState({
        loadingScreen: true,
      });
      this.getData(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedLocationStatus.value,
        this.state.selectedSortByDevice.value,
        this.state.countryId,
        this.state.cityId,
      );
    }

    if (this.state.countryId !== prevState.countryId) {
      this.setState({
        cityId: "",
        city: [],
        cities: [],
      });
    }
  }
  // fetching a data from the API
  getData = (
    startDate,
    endDate,
    location_Status,
    device_type,
    countryId,
    cityId,
  ) => {
    HeatMapAPI(
      moment(startDate).unix(),
      moment(endDate).unix(),
      location_Status,
      device_type,
      // groupBy || 0,
      // StoreCategoryId || 0,
      countryId || "",
      cityId || "",
      // page,
      // limit
    )
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.graph &&
          res.data.data.graph.xaxis
        ) {
          res.data.data.graph.xaxis.categories = dateTimeFormatForGraph(
            this.state.selectedGroupBy || "Hour",
            res.data.data.graph.xaxis.categories
          );
        }

        const heatmapCoordinate = [];
        const heatmapData = res.data.location;
        let center = res.data.centroid
        heatmapData.map((data, index) => {
         return  heatmapCoordinate.push([data.lat, data.long, data.intensity]);
        });
        // console.log("heatmapCoordinate", heatmapCoordinate);
        this.setState({
          heatmapData: heatmapCoordinate,
        })

        this.setState({
          dataLoaded: true,
          loadingScreen: false,
          center,
          // getheatmapData: true,
        });
      })
      .catch((err) => {
        console.log("err", err.response);
        if (!this.state.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Data Not Found For the Given Date Range!",
          });
        } else if ( err.response && err.response.status === 404) {
          this.setState({
            dataLoaded: false,
            loadingScreen: false,
            logsData: [],
            heatmapData: [],
            showSnackbar: true,
            snackBarMessage: err.response.data.message,
            noDataText: err.response.data.message,
          });
        } else if (err.response && err.response.status === 500) {
          this.setState({
            dataLoaded: false,
            loadingScreen: false,
            logsData: [],
            heatmapData: [],
            showSnackbar: true,
            snackBarMessage: "Internal Server Error",
            noDataText: "Internal Server Error",
          });
        } else {
          let message =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
              ? err.response.data.message
              : "Data Not Found For the Given Date Range!";
          this.setState({
            dataLoaded: false,
            loadingScreen: false,
            logsData: [],
            heatmapData: [],
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: "No data for the selected date range",
          });
        }
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            // tableLoader: false,
          });
        }, 1000);
      });
  };

  // redirecting a page on Dashboard
  goBack = () => {
    this.props.history.push("overview");
  };

  handleFilterData = (value, name) => {
    try {
      if (name === "initialCall") {
        getCountries()
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                countries: response.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryId: value === null ? "" : value,
        });
        getCities(value)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                cities: response.data.data,
                countryId: value,
              });
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data &&
              error.data.status === 204 &&
              name &&
              value
            ) {
              this.setState({
                countryId: value || "",
              });
            }
            console.log("cities api error ", error);
          });
      } else if (name === "cities") {
        this.setState({ cityId: value === null ? "" : value });
      }


      let key =
        name === "countries"
          ? "country"
          : name === "cities"
          ? "city"      
          : "";
      if (key) {
        const data = this.state[name];
        this.setState({
          [key]: _.result(
            _.find(data, function (obj) {
              return obj.id === value;
            }),
            "name"
          ),
        });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };

  sortSelectHandler = (option) => {
    if (
      option.label === "All" ||
      option.label === "Android" ||
      option.label === "iOS"
    ) {
      this.setState({
        selectedSortByDevice: option,
      });
    }

    if (option.label === "Pickup" || option.label === "Drop") {
      this.setState({
        selectedLocationStatus: option,
      });
    }
  };

  handleDateChange = (startDate, endDate) => {
    if (startDate !== this.state.startDate || endDate !== this.state.endDate) {
      this.setState({
        startDate: startDate,
        endDate: endDate,

        loading: true,
      });
    }
  };

  render() {
    if (this.state.mapHidden) {
      return (
        <div>
          <input
            type="button"
            value="Toggle Map"
            onClick={() => this.setState({ mapHidden: !this.state.mapHidden })}
          />
        </div>
      );
    }

    const gradient = {
      //   // 0.4: "blue",
      //   // 0.8: "orange",
      //   // 1.0: "red",
      // 0.1: "#89BDE0",
      // 0.2: "#96E3E6",
      // 0.4: "#82CEB6",
      // 0.6: "#FAF3A5",
      // 0.8: "#F5D98B",
      // 1.0: "#DE9A96",

      // 0.1: "red",
      // 0.3: "green",
      // 0.4: "#82CEB6",
      // 0.6: "#FAF3A5",
      // 0.8: "#F5D98B",
      // 1.0: "#DE9A96",
      // 0.4: 'blue', 0.65: 'lime', 1: 'red'


 
        '0': 'Black',
        '0.4': 'Purple',
        '0.6': 'Red',
        '0.8': 'Yellow',
        '1': 'White'
  

    };      
    const { country, city, center } = this.state;
    return (
      <div className="overviewWrapper" style={{ top: "0px" }}>
        <div className="head">
          <div
            className="hoverPathClass globalFontSize"
            onClick={() => this.props.history.push("tow-analytics-overview")}
          >
            <i className="fas fa-angle-left mr-2"></i>Reports
          </div>
          <div className="title">
            <span>Tow Heatmap</span>
          </div>
          <div className="d-flex flex-column px-2 py-3 w-100">
            <Filters
              countries={this.state.countries}
              cities={this.state.cities}
              selectHandle={this.handleFilterData}
              country={country}
              city={city}
              handleDateChange={this.handleDateChange}
              hideGroupBy={true}
            ></Filters>
          </div>

          <div className="d-flex align-items-center mt-2 justify-content-end ">
            <div className="d-flex justify-content-end align-items-center ml-3">
              <span className="mr-2">Location:</span>

              <div className="mr-2 globalFontSize">
                <ReactSelect
                  city={this.state.locationStatus}
                  change={this.sortSelectHandler}
                  selected={this.state.selectedLocationStatus}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center ml-3">
              <span className="mr-2">Device:</span>

              <div className="mr-2 globalFontSize">
                <ReactSelect
                  city={this.state.sortByDevice}
                  change={this.sortSelectHandler}
                  selected={this.state.selectedSortByDevice}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          // className="d-flex bg-white border align-items-center justify-content-between ml-2 mr-2"
          // style={{ minHeight: "500px", marginTop: "13rem" }}
          className="d-flex align-items-center mr-2 ml-2 justify-content-center"
          style={{
            marginTop: "13rem",
            backgroundColor: "white",
            borderRadius: "3px",
            minHeight: "500px",
            boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
            position: "relative"

          }}
        >
          { this.state.dataLoaded ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "relative"
              }}
            >
              { this.state.loadingScreen ?  // added loader or the map
              <div style={{ 
                position: "absolute",
                left:"50%",
                top:"50%",
                zIndex:"2"

              } } >
              
              <CircularProgress />
              </div> : <></>}
        
              <Map center={[center.lat, center.long]} 
              zoom={6} 
              minZoom={3} 
              scrollWheelZoom={true}>
                {!this.state.layerHidden && (
                  <HeatmapLayer
                    fitBoundsOnLoad
                    fitBoundsOnUpdate
                    points={this.state.heatmapData}
                    longitudeExtractor={(m) => m[1]}
                    latitudeExtractor={(m) => m[0]}
                    gradient={gradient}
                    intensityExtractor={(m) => parseFloat(m[2])}
                    radius={Number(this.state.radius)}
                    blur={Number(this.state.blur)}
                    max={Number.parseFloat(this.state.max)}
                  />
                )}
                <TileLayer noWrap={true} url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
              </Map>
            </div>
          ) : (
                <>
                { this.state.loadingScreen ? 
                  <div style={{ 
                    position: "absolute",
                    left:"50%",
                    top:"50%",
                    padding:"20px",
                    transform: "translate(-50%, -50%)",
                    zIndex:"2"
              } } >
              
              <CircularProgress />
              </div> 
               : <h4 style={{ color: "grey" }}>No data for selected date range</h4>} 

             </>
          )}
          <Snackbar
            open={this.state.showSnackbar}
            message={this.state.snackBarMessage}
          />
        </div>
      </div>
    );
  }
}
export default RideHeatMap;
