
export const ride_response = {
    "message": "success",
    "data": [
        {
            "typeId": "622f175fd63864465462606d",
            "topCountryId": "622ee03d31c9b037544dfce3",
            "topCountryName": "India",
            "topCityId": "622ee0ded80b8e5c7c60d353",
            "topCityName": "Surat",
            "topPickupZoneId": "622ee39431c9b037544dfce5",
            "topPickupZone": "India",
            "topDropZoneId": "622ee39431c9b037544dfce5",
            "topDropZone": "India",
            "topTripCount": 36,
            "topTripFare": 10995,
            "vehicleImgOff": "https://s3.amazonaws.com/ridecurrent/VehicleType/0/0/large/TESLA-MODEL-3_1649096743.jpeg",
            "typeName": "Honda",
            "totalTrip": 84,
            "totalFare": 11649.47,
            "topNPickups": {
                "graph": {
                    "series": [
                        {
                            "name": "Fare",
                            "data": [
                                0,
                                0,
                                10995,
                                654.47,
                                0
                            ]
                        }
                    ],
                    "xaxis": {
                        "categories": [
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                "India"
                            ],
                            [
                                "NYC",
                                "Operating",
                                "Zone"
                            ],
                            [
                                "India"
                            ]
                        ],
                        "title": "fare"
                    },
                    "yaxis": {
                        "title": "Location"
                    }
                }
            },
            "topNDrops": {
                "graph": {
                    "series": [
                        {
                            "name": "Fare",
                            "data": [
                                0,
                                10995,
                                644.47,
                                10,
                                0
                            ]
                        }
                    ],
                    "xaxis": {
                        "categories": [
                            [
                                ""
                            ],
                            [
                                "India"
                            ],
                            [
                                "NYC",
                                "Operating",
                                "Zone"
                            ],
                            [
                                "India"
                            ],
                            [
                                "India"
                            ]
                        ],
                        "title": "fare"
                    },
                    "yaxis": {
                        "title": "Location"
                    }
                }
            }
        },
        {
            "typeId": "624441eb1843280342029a2d",
            "topCountryId": "622ee69e1594a7707b3fbe63",
            "topCountryName": "United States of America",
            "topCityId": "622ee9edd638644654626063",
            "topCityName": "New York",
            "topPickupZoneId": "622eee4aae61e36ec47c8c03",
            "topPickupZone": "NYC Operating Zone",
            "topDropZoneId": "622eee4aae61e36ec47c8c03",
            "topDropZone": "NYC Operating Zone",
            "topTripCount": 10,
            "topTripFare": 521.7100000000002,
            "vehicleImgOff": "https://s3.amazonaws.com/ridecurrent/VehicleType/0/0/large/ESCALADE_1649091193.jpeg",
            "typeName": "SUV",
            "totalTrip": 10,
            "totalFare": 521.7100000000002,
            "topNPickups": {
                "graph": {
                    "series": [
                        {
                            "name": "Fare",
                            "data": [
                                0,
                                0,
                                0,
                                0,
                                521.7100000000002
                            ]
                        }
                    ],
                    "xaxis": {
                        "categories": [
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                "NYC",
                                "Operating",
                                "Zone"
                            ]
                        ],
                        "title": "fare"
                    },
                    "yaxis": {
                        "title": "Location"
                    }
                }
            },
            "topNDrops": {
                "graph": {
                    "series": [
                        {
                            "name": "Fare",
                            "data": [
                                0,
                                0,
                                0,
                                0,
                                521.7100000000002
                            ]
                        }
                    ],
                    "xaxis": {
                        "categories": [
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                "NYC",
                                "Operating",
                                "Zone"
                            ]
                        ],
                        "title": "fare"
                    },
                    "yaxis": {
                        "title": "Location"
                    }
                }
            }
        },
        {
            "typeId": "624b35342a99fe05380c04ee",
            "topCountryId": "622ee69e1594a7707b3fbe63",
            "topCountryName": "United States of America",
            "topCityId": "622ee9edd638644654626063",
            "topCityName": "New York",
            "topPickupZoneId": "622eee4aae61e36ec47c8c03",
            "topPickupZone": "NYC Operating Zone",
            "topDropZoneId": "622eee4aae61e36ec47c8c03",
            "topDropZone": "NYC Operating Zone",
            "topTripCount": 2,
            "topTripFare": 60.370000000000005,
            "vehicleImgOff": "https://s3.amazonaws.com/ridecurrent/VehicleType/0/0/large/HIGHLANDER_1649095458.jpeg",
            "typeName": "CURRENT PLUS",
            "totalTrip": 2,
            "totalFare": 60.370000000000005,
            "topNPickups": {
                "graph": {
                    "series": [
                        {
                            "name": "Fare",
                            "data": [
                                0,
                                0,
                                0,
                                0,
                                60.370000000000005
                            ]
                        }
                    ],
                    "xaxis": {
                        "categories": [
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                "NYC",
                                "Operating",
                                "Zone"
                            ]
                        ],
                        "title": "fare"
                    },
                    "yaxis": {
                        "title": "Location"
                    }
                }
            },
            "topNDrops": {
                "graph": {
                    "series": [
                        {
                            "name": "Fare",
                            "data": [
                                0,
                                0,
                                0,
                                0,
                                60.370000000000005
                            ]
                        }
                    ],
                    "xaxis": {
                        "categories": [
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                ""
                            ],
                            [
                                "NYC",
                                "Operating",
                                "Zone"
                            ]
                        ],
                        "title": "fare"
                    },
                    "yaxis": {
                        "title": "Location"
                    }
                }
            }
        }
    ]
}