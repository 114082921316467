// export const api = "https://api.utowme.com.au"


// export const api = "https://api.shoppd.net";
// export const loginApi = "https://api.shoppd.net/v1/manager/logIn";
// export const regionApi = "https://api.shoppd.net/v1/ipLocation";
// export const resetPasswordApi = 
//   "https://api.shoppd.net/v1/manager/forgotPassword";

  


// export const api = "https://api.ridewithcurrent.com";
// export const loginApi = "https://api.ridewithcurrent.com/v1/manager/logIn";
// export const regionApi = "https://api.ridewithcurrent.com/v1/ipLocation";
// export const resetPasswordApi = "https://api.ridewithcurrent.com/v1/manager/forgotPassword";
  

export const api = window._env_.API_URL;
export const loginApi = `${window._env_.API_URL}/v1/manager/logIn`;
export const regionApi = `${window._env_.API_URL}/v1/ipLocation`;
export const resetPasswordApi = `${window._env_.API_URL}/v1/manager/forgotPassword`;
