import { getForReport } from "../lib/request";

export const HeatMapAPI = (
  start_date,
  end_date,
  location_Status,
  device_type,
  country,
  city,
  zone
) => {
  let URL = `/tow/heatmap?start_timestamp=${start_date}&end_timestamp=${end_date}&device_type=${device_type}&country_id=${country}&city_id=${city}&location_status=${location_Status}`;
  return getForReport(URL);
};
