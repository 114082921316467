import { getForReport } from "../lib/request";

export const ridePaymentActivityApiCall = (
  start_time,
  end_time,
  groupBy,
  device,
  country_id,
  city_id,
  zone_id,
  from
) => {
  let URL = `/tow/payment?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${groupBy}&export=0&vehicle_type_id=0&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  if (from === "export") {
    return getForReport(`${URL}&export=1`);
  } else {
    return getForReport(URL);
  }
};
