import React, { Component } from "react";
import moment from "moment";
import ReactSelect from "../../../../components/reactSelect/reactSelect";
import ReactToPrint from "react-to-print";
import Apexcharts from "react-apexcharts";
import Modal from "../../../../components/modal/modal";
import Pbutton from "../../../../components/button/Button";
import { CSVLink } from "react-csv";
import { ridePaymentActivityApiCall } from "../../../../services/ridePaymentActivity";
import Snackbar from "../../../../components/snackbar/snackbar";

import TableComponent from "./table/table";

import "../../../../static/scss/globalComponent.scss";

// location filters
import {
  getCities,
  getCountries,
  getZones,

} from "../../../../services/rideLocationFilter";

import _ from "lodash";
import Filters from "../location/filter";
import { dateTimeFormatForGraph, tooltipFormatter } from "../../../../lib/helper";

class RidePaymentActivity extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      snackBarMessage: "",
      showSnackbar: false,

      dataLoaded: false,
      loader: true,
      storeIdValue: "0",

      country: "",
      city: "",
      countryId: "",
      cityId: "",
      countries: [],
      cities: [],

      currencyData: localStorage.getItem("currency")
      ? JSON.parse(localStorage.getItem("currency"))
      : window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "AUD",
      
    previousCurrencyData: localStorage.getItem("currency")
      ? JSON.parse(localStorage.getItem("currency"))
      : window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "AUD",
  
    // currencyData: window._env_.CURRENCY,
    // previousCurrencyData: window._env_.CURRENCY,       

      date: {
        startDate:
          localStorage.getItem("startDate") != null
            ? localStorage.getItem("startDate")
            : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        endDate:
          localStorage.getItem("endDate") != null
            ? localStorage.getItem("endDate")
            : moment().format("YYYY-MM-DD HH:mm:ss"),
      },

      selected: localStorage.getItem("selectedGroupBy")
        ? JSON.parse(localStorage.getItem("selectedGroupBy"))
        : { value: 0, label: "Hour" },
      selectData: [{ value: 0, label: "Hour" }],

      // device type sort
      sortByDevice: [
        { label: "All", value: 0 },
        { label: "Android", value: 1 },
        { label: "iOS", value: 2 },
      ],
      selectedSortByDevice: { label: "All", value: 0 },
    };
  }

  /**
   * @Author Jai
   * @Date 15 April, 2021
   * @Description Created a Ride Payment Activity Graph and table page as well addedd country, city, zone and device type filter
   */

  componentDidMount() {
    this.intermediate();
    this.handleFilterData("", "initialCall");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.date.startDate !== this.state.date.startDate ||
      prevState.date.endDate !== this.state.date.endDate ||
      this.state.countryId !== prevState.countryId ||
      this.state.cityId !== prevState.cityId ||
      prevState.selectedSortByDevice.value !==
        this.state.selectedSortByDevice.value ||
      this.state.selected.value !== prevState.selected.value
    ) {
      this.intermediate();
    }

    if (this.state.countryId !== prevState.countryId) {
      this.setState({
        cityId: "",
        city: [],
        cities: [],
      });
    }
  }

  intermediate = () => {
    this.getData(
      moment(this.state.date.startDate).unix(),
      moment(this.state.date.endDate).unix(),
      parseInt(this.state.selected.value),
      this.state.selectedSortByDevice.value,
      this.state.countryId,
      this.state.cityId,
    );
  };

  getData = (
    start_date,
    end_date,
    groupBy,
    selectedSortByDevice,
    countryId,
    cityId,
  ) => {
    ridePaymentActivityApiCall(
      start_date,
      end_date,
      groupBy || 0,
      selectedSortByDevice,
      countryId || "",
      cityId || "",
    )
      .then((res) => {
        let arrayCollection = [];
        let exportData = [];
        let data = {};
        let history = [];

        let xCategories = dateTimeFormatForGraph(
          this.state.selected.label || "Hour",
          res.data.data.graph.xaxis.categories
        );

        res.data.data.graph.xaxis.categories = [...xCategories];
        res.data.data.table.totalAmount["Time Line"] = [...xCategories];
        res.data.data.table.totalAmount.Card.map((item, index) => {
          Object.keys(res.data.data.history).map((data, dataIndex) => {
            if (
              this.state.selected.value === 3 ||
              this.state.selected.value === 7
            ) {
              if (
                data === res.data.data.table.totalAmount["Time Line"][index]
              ) {
                history = res.data.data.history[data];
              }
            } else {
              if (index === dataIndex) {
                history = res.data.data.history[data];
              }
            }
            return history;
          });
          data = {
            card: item,
            cash: res.data.data.table.totalAmount.Cash[index],
            "Corporate Wallet":
              res.data.data.table.totalAmount["Corporate Wallet"][index],
            timeStamp: res.data.data.table.totalAmount["Time Line"][index],
            history,
          };

          return arrayCollection.push(data);
        });

        arrayCollection.map((object) =>
          object.history.map((historyObject) =>
            exportData.push({
              timeStamp: object.timeStamp,
              "Total Card": object.card,
              "Total Cash": object.cash,
              "Store Category": historyObject["Store Category"],
              Card: historyObject.card,
              Cash: historyObject.cash,
              "Corporate Wallet": historyObject["Corporate Wallet"],
            })
          )
        );
        // graph fromatting

        let graphData = {
          series: [...res.data.data.graph.series],
          options: {
            plotOptions: { bar: { columnWidth: "30%" } },
            title: {
              text: "",
              align: "left",
              style: { fontSize: "18px", fontWeight: 500 },
            },
            dataLabels: { enabled: false },
            xaxis: {
              type: "category",
              // offsetX:10,
              categories: [...res.data.data.graph.xaxis.categories],
              title: {
                text: res.data.data.graph.xaxis.title,
                style: { fontSize: "11px", fontWeight: 700 },
                // offsetY: 10,
              },
              labels: {
                show: true,
                rotate: -50,
                hideOverlappingLabels: true,
                showDuplicates: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            grid: {
              padding: {
                left: 40,
              },
            },
            legend: {
              position: "top",
              showForSingleSeries: true,
            },
            yaxis: {
              title: {
                text: res.data.data.graph.yaxis.title,
                style: { fontSize: "11px", fontWeight: 700 },
              },
              opposite: false,
                labels: {
                offsetX:20,
                show: true,
                style: { fontSize: "11px" },
                formatter: function (value) {
                  return parseInt(value)  === value ? value  : '';
                },
                // formatter: (val, index) => {
                //   return tooltipFormatter(val, this.state.currencyData, false);
                // },                
              },
            },
            tooltip: {
              enabled: true,
              followCursor: true,
            },
          },
        };

        this.setState({
          dataLoaded: true,
          loader: false,
          graphData,
          tableData: arrayCollection,
          logsData: exportData,
        });
      })
      .catch((error) => {
        this.setState({
          dataLoaded: false,
          loader: false,
        });
        if (!this.state.startDate) {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Data Not Found For the Given Date Range!",
          });
        } else if (error && error.data && error.data.status === 204) {
          this.setState({
            showSnackbar: true,
            noData: true,
            snackBarMessage: "Data Not Found For the Given Date Range!",
          });
          setTimeout(() => {
            this.setState({ snackBarMessage: "", showSnackbar: false });
          }, 1500);
        } else {
          let message =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : "Data Not Found For the Given Date Range!";
          this.setState({
            tableData: [],
            showSnackbar: true,
            snackBarMessage: message,
            noDataText: "No data for the selected date range",
            loader: false,
          });
        }

        setTimeout(() => {
          this.setState({
            showSnackbar: false,
          });
        }, 1000);
        console.log("Api error", error);
      });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  sortSelectHandler = (option) => {
    if (option.value === 0 || option.value === 1 || option.value === 2) {
      this.setState({
        selectedSortByDevice: option,
      });
    }
  };

  handleFilterData = (value, name) => {
    try {
      if (name === "initialCall") {
        getCountries()
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                countries: response.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryId: value === null ? "" : value,
        });
        getCities(value)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.setState({
                cities: response.data.data,
                countryId: value,
              });
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data &&
              error.data.status === 204 &&
              name &&
              value
            ) {
              this.setState({
                countryId: value || "",
              });
            }
            console.log("cities api error ", error);
          });
      } else if (name === "cities") {
        this.setState({ cityId: value === null ? "" : value });
      }

      let key =
        name === "countries"
          ? "country"
          : name === "cities"
          ? "city"
          : "";
      if (key) {
        const data = this.state[name];
        this.setState({
          [key]: _.result(
            _.find(data, function (obj) {
              return obj.id === value;
            }),
            "name"
          ),
        });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };

  handleDateRange = (startDate, endDate, groupBy, selectedGroupBy) => {
    this.setState({
      date: {
        startDate: startDate,
        endDate: endDate,
      },
      selectData: groupBy,
      selected: selectedGroupBy,
      loader: true,
    });
  };

  selectHandler = (option) => {
    this.setState({ selected: option, loader: true });
  };

  render() {
    const { country, city } = this.state;
    return (
      <div className="containerDiv" ref={this.myRef}>
        <span
          className="hoverPathClass globalFontSize"
          onClick={() => this.props.history.push("tow-analytics-overview")}
        >
          <i className="fas fa-angle-left mr-2"></i>Reports
        </span>
        <div className="title">Tow Payment Activity</div>
        <div className="d-flex text-grey mt-2" style={{ marginBottom: "" }}>
          <div className="mr-3 db_ptr">
            <ReactToPrint
              trigger={() => (
                <span>
                  <i className="fas fa-print mr-1"></i>Print
                </span>
              )}
              content={() => this.myRef.current}
            />
          </div>
          <div className="mr-3 db_ptr" onClick={this.toggleModal}>
            <i className="fas fa-download mr-1"></i>Export
          </div>
        </div>

        <div className="d-flex flex-column py-3 w-100">
          <Filters
            countries={this.state.countries}
            cities={this.state.cities}
            selectHandle={this.handleFilterData}
            country={country}
            city={city}
            handleDateChange={this.handleDateRange}
            groupByselectData={this.state.selectData}
            groupByselectHandler={this.selectHandler}
            groupByselected={this.state.selected}
          ></Filters>
        </div>

        <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
          <div className="d-flex justify-content-end align-items-center ml-auto">
            <span className="mr-2">Device:</span>

            <div className="mr-2 globalFontSize">
              <ReactSelect
                className={"custom-z-index"}
                city={this.state.sortByDevice}
                change={this.sortSelectHandler}
                selected={this.state.selectedSortByDevice}
              />
            </div>
          </div>
        </div>
        <div
          className="activeBarGraph d-flex align-items-center justify-content-center"
          style={{ padding: 0 }}
        >
          {this.state.loader ? (
            <h4 style={{ color: "grey" }}>Loading data...</h4>
          ) : this.state.dataLoaded ? (
            <div
              style={{
                height: "100%",
                padding: "10px 0px 10px 0px",
                width: "100%",
              }}
            >
              <Apexcharts
                series={this.state.graphData.series}
                options={this.state.graphData.options}
                type="line"
                height={"100%"}
              />
            </div>
          ) : (
            <h4 style={{ color: "grey" }}>No data for selected date range</h4>
          )}
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            marginTop: "50px",
            backgroundColor: "white",
            borderRadius: "3px",
            minHeight: "100px",
            boxShadow: "0px 0px 2px 1px rgba(145, 145, 145, .5)",
          }}
        >
          {this.state.loader ? (
            <h4 style={{ color: "grey" }}>Loading data...</h4>
          ) : this.state.dataLoaded ? (
            <TableComponent tableData={this.state.tableData} />
          ) : (
            <h4 style={{ color: "grey" }}>No data for selected date range</h4>
          )}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="d-flex justify-content-end">
                <Pbutton
                  onClick={this.toggleModal}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>
                <CSVLink
                  onClick={() => this.toggleModal()}
                  data={this.state.logsData ? this.state.logsData : ""}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackBarMessage}
        />
      </div>
    );
  }
}

export default RidePaymentActivity;
