// import { get } from "../lib/request";
import { getForReport } from "../lib/request";

// Ride Count Analytics
export const fetchTopRides = (
  start_time,
  end_time,
  // groupBy,
  sortBy,
  device,
  top,
  currency,
  country_id,
  city_id,
  zone_id
) => {
  // let URL = `/tow/top/location?&start_timestamp=1640975400&end_timestamp=1649834104&sort=2&top=5&device_type=0&currency=USD&country_id=&city_id=&store_id=0&timezone=Asia%2FCalcutta&currency_symbol=$`

  let URL = `/tow/top/location?&start_timestamp=${start_time}&end_timestamp=${end_time}&sort=${sortBy}&top=${top}&device_type=${device}&currency=${currency}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};

// export const storeIdApiCall = () => {
//   return getForReport("/python-spark/store-category");
// };
