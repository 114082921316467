// import { get, getForReport } from "../lib/request";
import { getForReport } from "../lib/request";

// Ride Count Analytics
export const fetchRideCount = (
  start_time,
  end_time,
  group_by,
  status,
  device,
  country_id,
  city_id,
) => {
  let URL = `/tow/count?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&status=${status}&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};

// tow Count Table
export const fetchRideTableCount = (
  start_time,
  end_time,
  group_by,
  status,
  device,
  country_id,
  city_id,
) => {
  let URL = `/tow/table/count?start_timestamp=${start_time}&end_timestamp=${end_time}&status=${status}&group_by=${group_by}&vehicle_type_id=0&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  console.log("url", URL)
  return getForReport(URL);
};
