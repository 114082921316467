import { getForReport } from "../lib/request";

// Ride Count Analytics
export const fetchRideSurge = (
  start_time,
  end_time,
  group_by,
  currency,
  device,
  country_id,
  city_id,
  zone_id
) => {
  let URL = `/tow/surge?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&vehicle_type_id=0}&currency=${currency}&device_type=${device}&country_id=${country_id}&city_id=${city_id}`;
  return getForReport(URL);
};
