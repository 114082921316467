//library import
import async from "async";
import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from "react";
import Masonry from "react-masonry-css";

import { setIndex } from "../../../redux/actions/auth";

import Headers from "./header/index.js";
import Snackbar from "../../../components/snackbar/snackbar";
import StatusIdDropDown from "../../../components/StatusIdDropdown";
import ReactSelect from "../../../components/reactSelect/reactSelect";

// import graphs
import RideCountGraph from "./graphs/rideCountOverview";
import RideStatusGraph from "./graphs/rideStatusOverview";
import RidePaymentGraph from "./graphs/ridePaymentOverview";
import RideRevenueGraph from "./graphs/rideRevenueOverview";

import { chartConfig } from "./graphConfig/chartConfig";
import { countries } from "../../../fixtures/dummyApi/countries";
import { statusFilter } from "../../../fixtures/dateFilter/statusFilter";

// css file
import "./Overview.scss";
import "../global-font-css.css";

//heper
import {
  tooltipFormatter,
  isFloat,
  setInLocalStorage,
  dateTimeFormatForGraph,
} from " ../../../src/lib/helper";

// API request services
import {
  DashboardRideCount,
  DashboardRideStatus,
  RidePaymentActivity,
  RideRevenueOverview,
} from "../../../services/rideAnalyticsOverview";

import {
  getCities,
  getCountries,
} from "../../../services/rideLocationFilter";

import _ from "lodash";

/**
 * @Author Jai
 * @Date 14 April, 2021
 * @Description Created a dashboard for Ride Analytics Overview and added a ride count graph as well added country, city, zone and device type filter
 */

class Dashboard extends Component {
  state = {
    dataLoaded: false,
    isOpenSnackbar: false,
    snackbarMessage: "",

    // status id
    // groupBy: 0,
    storeIdValue: "0",
    storeData: statusFilter,

    isDisplayChart: false,
    noDataText: "",

    // currency
    selectedCurrency: window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "AUD",
    previousCurrency: window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "AUD",
    dataForCurrency: window && window._env_ && window._env_.CURRENCY ?  window._env_.CURRENCY : "AUD",

    filterData: [...countries],
    anchorEl: null,
    currencyFilter: {
      label: "Currency filter",
      input: {
        name: "currencyFilter",
        type: "text",
        value: "",
      },
    },

    totalRideCount: 0,
    rideStatusCount: 0,
    ridePaymentCount: 0,
    rideRevenueCount: 0,

    rideCountLoader: true,
    rideStatusLoader: true,
    ridePaymentLoader: true,
    rideRevenueLoader: true,

    rideCountConfig: chartConfig,
    rideStatusConfig: chartConfig,
    ridePaymentConfig: chartConfig,
    rideRevenueConfig: chartConfig,

    country: "",
    city: "",
    countryId: "",
    cityId: "",
    countries: [],
    cities: [],

    startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().format("YYYY-MM-DD HH:mm:ss"),

    groupByName: { value: 0, label: "Hour" },
    sortBy: 1,
    groupBy: 0,

    // device type sort
    sortByDevice: [
      { label: "All", value: 0 },
      { label: "Android", value: 1 },
      { label: "iOS", value: 2 },
    ],
    selectedSortByDevice: { label: "All", value: 0 },
  };

  componentDidMount = () => {
    let width = window.innerWidth;
    this.setState({ width });
    window.addEventListener("resize", this.heightHandler);
    const { startDate, endDate, groupByName } = this.state;
    localStorage.setItem("startDate", startDate);
    localStorage.setItem("endDate", endDate);
    localStorage.setItem("selectedGroupBy", JSON.stringify(groupByName));

    this.handleFilterData("", "initialCall");
    this.getData(
      moment(this.state.startDate).unix(),
      moment(this.state.endDate).unix(),
      this.state.groupBy,
      this.state.storeIdValue,
      this.state.selectedCurrency,
      this.state.selectedSortByDevice.value,
      this.state.countryId,
      this.state.cityId,
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedCurrency !== this.state.selectedCurrency ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.storeIdValue !== this.state.storeIdValue ||
      this.state.countryId !== prevState.countryId ||
      this.state.cityId !== prevState.cityId ||
      prevState.selectedSortByDevice.value !==
        this.state.selectedSortByDevice.value ||
      this.state.groupBy !== prevState.groupBy
    ) {
      this.setState({
        tableLoader: true,
        rideCountLoader: true,
        rideStatusLoader: true,
        ridePaymentLoader: true,
        rideRevenueLoader:true,

        isDisplayChart: false,
        noDataText: "Loading Data ...",
      });

      this.getData(
        moment(this.state.startDate).unix(),
        moment(this.state.endDate).unix(),
        this.state.groupBy,
        this.state.storeIdValue,
        this.state.selectedCurrency,
        this.state.selectedSortByDevice.value,
        this.state.countryId,
        this.state.cityId,
      );
    }
    if (this.state.countryId !== prevState.countryId) {
      this.setState({
        cityId: "",
        city: [],
        cities: [],
      });
    }
  }

  valueFormatter = (value) => {
    if (value >= 10000 && value < 1000000) {
      let kValue = value / 1000;
      let isDecimal = kValue % 1;
      if (isDecimal) {
        return kValue.toFixed(0) + " K";
      }
      return kValue.toFixed(0) + " K";
    }
    if (value >= 100000) {
      let kValue = value / 1000000;

      return kValue.toFixed(2) + " M";
    }
    return Number(value.toFixed(2));
  };

  getGraphConfigData = (response, config) => {
    try {
      // console.log("group by", this.state.groupByName.label);
      let xCategories = dateTimeFormatForGraph(
        this.state.groupByName.label || "Hour",
        response.data.graph.xaxis.categories
      );
      let format = {
        Hour: "ll H:00",
        Day: "ll",
        Week: "ll",
        Month: "MMM YY",
        "Hour Of Day": "HH:00",
      };
      let label = this.state.groupByName.label;
      let tickAmount = xCategories.length > 7 ? 5 : xCategories.length;
      let xaxisType =
        xCategories.length < 8 ||
        label === "Hour" ||
        label !== "Day" ||
        label === "Year" ||
        label === "Quarter" ||
        label === "Day Of Week"
          ? "category"
          : "datetime";
      let tickPlacement = xaxisType !== "datetime" ? "between" : "on";

      let formatter =
      config === "sales"
        ? (value, index) => {
            if (value >= 10000 && value < 1000000) {
              let kValue = value / 1000;
              return Number(kValue.toFixed(2)) + " K";
            }
            if (value >= 1000000 && value < 1000000000 ) {
              let kValue = value / 1000000;
              return Number(kValue.toFixed(2)) + " M";
            }
            
            if (value >= 1000000000 && value < 1000000000000) {
              let kValue = value / 1000000000;
              return Number(kValue.toFixed(2)) + " B";
            }
            
            if (value >= 1000000000000) {
              let kValue = value / 1000000000000;
              return Number(kValue.toFixed(2)) + " T";
            }
            return value && Number.isInteger(value) ? Number(value.toFixed()) : isFloat(value) ? value.toFixed(2) : value;
          }
        : (val, index) => parseInt(val) === val ? val  : '';



      response.data.graph.xaxis.categories = [...xCategories];
      // console.log("tick",tickAmount, tickPlacement)
      //graph configuration
      let graphData = {
        series: [...response.data.graph.series],
        options: {
          plotOptions: { bar: { columnWidth: "30%" } },
          title: {
            text: "",
            align: "left",
            style: { fontSize: "18px", fontWeight: 500 },
          },
          dataLabels: { enabled: false },
          background: {
            enabled: false,
          },
          legend: {
            position: "top",
            showForSingleSeries: true,
            horizontalAlign: "center",
            onItemClick: {
              toggleDataSeries: true,
            },
            onItemHover: {
              highlightDataSeries: true,
            },
          },

          chart: {
            width: "100%",
            toolbar: {
              show: false,
            },
            zoom: {
              type: "x",
              enabled: false,
              autoScaleYaxis: true,
            },
          },
          xaxis: {
            title: {
              text: response.data.graph.xaxis.title,

              style: {
                fontSize: "11px",
                fontWeight: 700,
              },
            },
            // categories: [...response.data.graph.xaxis.categories],
            categories: xCategories,
            type: xaxisType,
            tickAmount: tickAmount,
            tickPlacement: tickPlacement,
            tooltip: {
              enabled: false,
            },
            labels: {
              show: true,
              rotate: -45,
              hideOverlappingLabels: true,
              rotateAlways: true,
              trim: true,
              style: { fontSize: "11px" },
              formatter: function (value, timestamp, index) {
                let isValid = moment(value).isValid() && label !== "Year";
                return isValid ? moment(value).format(format[label]) : value;
              },
            },
          },
          grid: {
            // providing padding in the graph
            padding: {
              left: 30,
            },
          },

          yaxis: {
            title: {
              text: response.data.graph.yaxis.title,
              style: {
                fontSize: "11px",
                fontWeight: 700,
              },
            },
            floating :false,
            labels: {
              show: true,
              offsetX: 15,
              formatter: formatter,
            },
          },

          tooltip: {
            enabled: true,
            followCursor: true,
            shared: true,
            y: {
              formatter:
                config === "sales"
                  ? (val, series) => {
                      return tooltipFormatter(
                        val,
                        this.state.dataForCurrency,
                        true
                      );
                    }
                  : undefined,
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },
        },
      };

      return graphData;
    } catch (error) {
      console.log("Error in Chart Configuration ", error);
      return error;
    }
  };

  dropDownSelect = (e) => {
    this.setState({
      storeIdValue: e.target.value,
    });
  };

  getData = (
    start_date,
    end_date,
    groupBy,
    status,
    currency,
    selectedSortByDevice,
    countryId,
    cityId,
  ) => {
    setInLocalStorage("currency", this.state.selectedCurrency);
    async.parallel(
      {
        rideCount: (callback) => {
          DashboardRideCount(
            start_date,
            end_date,
            groupBy || 0,
            status,
            selectedSortByDevice,
            countryId || "",
            cityId || "",
          )
            .then((response) => {
              try {
                console.log("coun api res", typeof(response))
                let rideCountConfig = this.getGraphConfigData(
                  response,
                  "count"
                );
                this.setState({
                  rideCountConfig,
                  totalRideCount: response.data.count,
                  rideCountLoader: false,
                  dataForCurrency: this.state.selectedCurrency,
                });
              } catch (error) {
                this.setState({
                  rideCountLoader: false,
                  totalRideCount: 0,
                  rideCountConfig: {
                    ...chartConfig,
                    options: {
                      ...chartConfig.options,
                      noData: {
                        ...chartConfig.options.noData,
                        title: "No data for selected date range",
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                // setTimeout(() => {
                //   this.setState({
                //   });
                // }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  rideCountLoader: false,
                  snackbarMessage: "selected currency is not supported",
                  dataForCurrency: this.state.selectedCurrency,
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  rideCountLoader: false,
                  totalRideCount: 0,
                  isOpenSnackbar: true,
                  // currencyData: this.state.previousCurrencyData,
                  snackbarMessage:
                    error.response && error.response.status === 500
                      ? "Internal Server Error"
                      : "No data found Tow Count!",
                  rideCountConfig: {
                    ...chartConfig,
                    options: {
                      ...chartConfig.options,
                      noData: {
                        ...chartConfig.options.noData,
                        text: "No data for selected date range",
                        style: {
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "sans-serif",
                          color: "#263238",
                        },
                      },
                      legend: {
                        showForSingleSeries: false,
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        rideStatus: (callback) => {
          DashboardRideStatus(
            start_date,
            end_date,
            groupBy || 0,
            status,
            // currency,
            selectedSortByDevice,
            countryId || "",
            cityId || "",
          )
            .then((response) => {
              try {
                let rideStatusConfig = this.getGraphConfigData(
                  response.data,
                  "status"
                );
                this.setState({
                  rideStatusConfig,
                  rideStatusCount: response.data.data.count,
                  rideStatusLoader: false,
                  dataForCurrency: this.state.selectedCurrency,
                });
              } catch (error) {
                this.setState({
                  rideStatusLoader: false,
                  rideStatusCount: 0,
                  rideStatusConfig: {
                    ...chartConfig,
                    options: {
                      ...chartConfig.options,
                      noData: {
                        ...chartConfig.options.noData,
                        title: "No data for selected date range",
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                // setTimeout(() => {
                //   this.setState({
                //   });
                // }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  rideStatusLoader: false,
                  snackbarMessage: "selected currency is not supported",
                  // currencyData: this.state.previousCurrencyData,
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  rideStatusLoader: false,
                  rideStatusCount: 0,
                  isOpenSnackbar: true,
                  snackbarMessage:
                    error.response && error.response.status === 500
                      ? "Internal Server Error"
                      : "No data found for Ride Cancel Status!",
                  rideStatusConfig: {
                    ...chartConfig,
                    options: {
                      ...chartConfig.options,
                      noData: {
                        ...chartConfig.options.noData,
                        text: "No data for selected date range",
                        style: {
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "sans-serif",
                          color: "#263238",
                        },
                      },
                      legend: {
                        showForSingleSeries: false,
                      },

                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        ridePayment: (callback) => {
          RidePaymentActivity(
            start_date,
            end_date,
            groupBy || 0,
            status,
            // currency,
            selectedSortByDevice,
            countryId || "",
            cityId || "",
          )
            .then((response) => {
              try {
                let ridePaymentConfig = this.getGraphConfigData(
                  response.data,
                  "status"
                );

                this.setState({
                  ridePaymentCount: response.data.count,
                  ridePaymentConfig,
                  ridePaymentLoader: false,
                  dataForCurrency: this.state.selectedCurrency,
                });
              } catch (error) {
                this.setState({
                  ridePaymentLoader: false,
                  ridePaymentCount: 0,
                  ridePaymentConfig: {
                    ...chartConfig,
                    options: {
                      ...chartConfig.options,
                      noData: {
                        ...chartConfig.options.noData,
                        title: "No data for selected date range",
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                // setTimeout(() => {
                //   this.setState({
                //   });
                // }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  ridePaymentLoader: false,
                  snackbarMessage: "selected currency is not supported",
                  dataForCurrency: this.state.selectedCurrency,
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  ridePaymentLoader: false,
                  ridePaymentCount: 0,
                  isOpenSnackbar: true,
                  snackbarMessage:
                    error.response && error.response.status === 500
                      ? "Internal Server Error"
                      : "No data found for Ride Payment Activity!",
                  ridePaymentConfig: {
                    ...chartConfig,
                    options: {
                      ...chartConfig.options,
                      noData: {
                        ...chartConfig.options.noData,
                        text: "No data for selected date range",
                        style: {
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "sans-serif",
                          color: "#263238",
                        },
                      },
                      legend: {
                        showForSingleSeries: false,
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
        rideRevenue: (callback) => {
          RideRevenueOverview(
            start_date,
            end_date,
            groupBy || 0,
            // status,
            currency,
            selectedSortByDevice,
            countryId || "",
            cityId || "",
          )
            .then((response) => {
              try {
                let rideRevenueConfig = this.getGraphConfigData(
                  response,
                  "sales"
                );

                this.setState({
                  rideRevenueConfig,
                  rideRevenueCount: response.data.count,
                  rideRevenueLoader: false,
                  dataForCurrency: this.state.selectedCurrency,
                });
              } catch (error) {
                this.setState({
                  rideRevenueLoader: false,
                  rideRevenueCount: 0,
                  rideRevenueConfig: {
                    ...chartConfig,
                    options: {
                      ...chartConfig.options,
                      noData: {
                        ...chartConfig.options.noData,
                        title: "No data for selected date range",
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                // setTimeout(() => {
                //   this.setState({
                //     rideRevenueLoader: false,
                //   });
                // }, 1000);
                callback(error, null);
              }
            })
            .catch((error) => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === "currency conversion not found"
              ) {
                this.setState({
                  isOpenSnackbar: true,
                  rideRevenueLoader: false,
                  snackbarMessage: "selected currency is not supported",
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              } else {
                this.setState({
                  // currencyData: this.state.previousCurrencyData,
                  rideRevenueLoader: false,
                  isOpenSnackbar: true,
                  rideRevenueCount: 0,
                  snackbarMessage:
                    error.response && error.response.status === 500
                      ? "Internal Server Error"
                      : "No data found for Ride Revenue!",
                  rideRevenueConfig: {
                    ...chartConfig,
                    options: {
                      ...chartConfig.options,
                      noData: {
                        ...chartConfig.options.noData,
                        text: "No data for selected date range",
                        style: {
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "sans-serif",
                          color: "#263238",
                        },
                      },
                      legend: {
                        showForSingleSeries: false,
                      },
                      xaxis: { labels: { show: false } },
                    },
                  },
                });
                setTimeout(() => {
                  this.setState({
                    isOpenSnackbar: false,
                    snackbarMessage: "",
                  });
                }, 1000);
              }
              callback(error, null);
            });
        },
      },
      (err, data) => {
        if (err) console.log(err);
      }
    );
  };

  handleFilterData = (value, name) => {
    try {
      if (name === "initialCall") {
        getCountries()
          .then((response) => {
            console.log(response.data)
            if (response && response.data && response.data.data) {
              this.setState({
                countries: response.data.data,
              });
            }
          })
          .catch((error) => {
            console.log("Countries api error", error);
          });
      }
      if (name === "countries") {
        this.setState({
          country: name,
          countryId: value === null ? "" : value,
        });
        getCities(value)
          .then((response) => {
            console.log("city res", response)
            if (response && response.data && response.data.data) {
              this.setState({
                cities: response.data.data,
                countryId: value,
              });
            }
          })
          .catch((error) => {
            if (
              error &&
              error.data &&
              error.data.status === 204 &&
              name &&
              value
            ) {
              this.setState({
                countryId: value || "",
              });
            }
            console.log("cities api error ", error);
          });
      } 
      else if (name === "cities") {
        this.setState({ cityId: value === null ? "" : value });
      }

      let key =
        name === "countries"
          ? "country"
          : name === "cities"
          ? "city"
          : "";
      if (key) {
        const data = this.state[name];
        this.setState({
          [key]: _.result(
            _.find(data, function (obj) {
              return obj.id === value;
            }),
            "name"
          ),
        });
      }
    } catch (err) {
      console.log("Something went wrong here", err);
    }
  };

  headerStateHandler = (stateName, stateValue) => {
    if (
      stateName === "selectedCurrency" &&
      stateValue !== this.state.selectedCurrency
    ) {
      this.setState({
        previousCurrency: this.state.selectedCurrency,
        selectedCurrency: stateValue,
      });
    }
    if (stateName === "date" && stateValue) {
      this.setState({
        startDate: stateValue.startDate,
        endDate: stateValue.endDate,
      });
    }
    if (stateName === "groupBy" && (stateValue === 0 || stateValue)) {
      this.setState({
        groupBy: stateValue,
      });
    }
    if (stateName === "groupByName" && stateValue) {
      this.setState({
        groupByName: stateValue,
      });
    }
  };

  sortSelectHandler = (option) => {
    if (option.value === 0 || option.value === 1 || option.value === 2) {
      this.setState({
        selectedSortByDevice: option,
      });
    }
  };

  render() {
    const breakpointColumnsObj = {
      default: 3,
      1100: 2,
      700: 1,
      500: 1,
    };

    const { country, city } = this.state;
    return (
      <div className="overviewWrapper">
        <div className="head">
          <div className="title">
            <span>Tow Analytics Overview</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mt-2">
              <Headers
                groupBy={this.state.groupBy}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                headerStateHandler={this.headerStateHandler}
                groupByName={this.state.groupByName}
                countries={this.state.countries}
                cities={this.state.cities}
                selectHandle={this.handleFilterData}
                country={country}
                city={city}
                currency={this.state.selectedCurrency}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <div className="d-flex justify-content-end align-items-center ml-auto">
              <StatusIdDropDown
                storeData={this.state.storeData}
                storeID={this.state.storeIdValue}
                dropDownSelect={this.dropDownSelect}
              />
            </div>

            <div className="d-flex justify-content-end align-items-center ml-3">
              <span className="mr-2">Device:</span>

              <div className="mr-2 globalFontSize">
                <ReactSelect
                  className={"custom-z-index"}
                  city={this.state.sortByDevice}
                  change={this.sortSelectHandler}
                  selected={this.state.selectedSortByDevice}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="overviewBody" style={{ marginTop: "11rem" }}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <RideCountGraph
              rideCount={this.valueFormatter(this.state.totalRideCount)}
              loader={this.state.rideCountLoader}
              config={this.state.rideCountConfig}
              currency={this.state.selectedCurrency}
            />
            <RideStatusGraph
              rideStatusCount={this.valueFormatter(this.state.rideStatusCount)}
              loader={this.state.rideStatusLoader}
              config={this.state.rideStatusConfig}
              currency={this.state.selectedCurrency}
            />
            <RidePaymentGraph
              ridePaymentCount={this.valueFormatter(
                this.state.ridePaymentCount
              )}
              loader={this.state.ridePaymentLoader}
              config={this.state.ridePaymentConfig}
              currency={this.state.selectedCurrency}
            />
            <RideRevenueGraph
              rideRevenueCount={this.valueFormatter(
                this.state.rideRevenueCount
              )}
              loader={this.state.rideRevenueLoader}
              config={this.state.rideRevenueConfig}
              currency={this.state.selectedCurrency}
            />
          </Masonry>
        </div>
        <Snackbar
          open={this.state.isOpenSnackbar}
          message={this.state.snackbarMessage}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIndex: (index) => dispatch(setIndex(index)),
  };
};

export default connect(null, mapDispatchToProps)(Dashboard);
// export default withRouter(withStyles(styles)(RideRevenue));
